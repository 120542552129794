import Marionette from 'lib/marionette'

import vent from 'util/vent'
import TimelineGrowlerTpl from 't/timeline/timeline-growler.tpl'

export default Marionette.ItemView.extend({
  // Overrides
  // -------------------------------------------------------------------

  initialize: function(opts) {
    this.subtype = opts.subtype
    this.listenTo(vent, 'growler:show', this.showGrowler)
    this.listenTo(vent, 'growler:hide', this.hideGrowler)
  },

  ui: {
    growler: '.growler',
  },

  events: {
    'click .js-growler': function() {
      vent.trigger('growler:resume', this.subtype)
    },
  },

  template: TimelineGrowlerTpl,

  // Custom
  // -------------------------------------------------------------------

  showGrowler: function(room, msg) {
    if (this.subtype === room) {
      this.ui.growler.find('.js-growler-count').text(msg.text)
      this.ui.growler.addClass('is-displayed')
    }
  },

  hideGrowler: function(room) {
    if (this.subtype === room) {
      this.ui.growler.removeClass('is-displayed')
    }
  },
})
