// vendor
import $ from 'lib/jquery'
import Marionette from 'lib/marionette'
import Backbone from 'backbone'
import _ from 'lib/underscore'

// lib
import check from 'util/check'
import vent from 'util/vent'
import RTimelineFiltersDiTpl from 't/timeline/r-timeline-filters-di.tpl'
import RTimelineFiltersTpl from 't/timeline/r-timeline-filters.tpl'

const config = window.VO_CONFIG

var buildRouteFilterString = function(route) {
  var data = route.get('routeKey')
  var isNew = route.get('routeIsNew')
  var label = data
  var tpl = _.template(
    '<li data-className="action-<%- label %>" data-route="<%- data %>"><input id="pair-<%- data %>" type="checkbox"/> <label for="pair-<%- data %>"><%- label %></label></li>'
  )

  var $el = $(
    tpl({
      data: data,
      label: label,
    })
  )

  var isChecked = route.get('state') === 'on'

  if (isNew) {
    $el.find('label').addClass('is-new')
  }

  if (data === 'default') {
    $el.attr(
      'title',
      "This is a special filter for alerts that don't contain a routing key."
    )
  }

  $el.find('input').prop('checked', isChecked)

  return $el
}

export default Marionette.ItemView.extend({
  // Overrides
  // -------------------------------------------------------------------

  initialize: function(opts) {
    _.bindAll(this, [
      'onDestroy',
      'getTemplate',
      'displayConfCallFilter',
      'onRender',
      'toggleFiltersGroup',
      'renderRouteFilters',
      'toggleFilters',
      'docOnClick',
      'docOnKeydown',
      'clickTabs',
      'updateIcon',
      'onCheckbox',
      'onGroupCheckbox',
      'onRouteCheckbox',
      'onRouteGroupCheckbox',
      'onFilter',
    ])

    this.ROOM_ID = opts.ROOM_ID // room id isn't currently set
    this.routes = opts.routes
    this.groups = opts.routeGroups
    this.filtersGroup = opts.filtersGroup
    this.model = new Backbone.Model({ slug: config.auth.org.slug })

    this.listenTo(
      vent,
      'filters:toggle',
      _.bind(function() {
        this.toggleFilters()
      }, this)
    )

    this.listenTo(vent, 'route_keys:render', this.renderRouteFilters)

    $(document).on('click', this.docOnClick)
    $(document).on('keydown', this.docOnKeydown)
  },

  onDestroy: function() {
    $(document).off('click', this.docOnClick)
    $(document).off('keydown', this.docOnKeydown)
    this.stopListening()
  },

  getTemplate: function() {
    return this.options.diBool ? RTimelineFiltersDiTpl : RTimelineFiltersTpl
  },

  ui: {
    filters: '.js-filters',
    tabs: '.js-timeline-filter-tabs',
    routesTab: '.js-tab-route-keys',
    messagesTab: '.js-tab-message-types',
    routesToggle: '.js-tab-route-keys .all-or-selected',
    typesToggle: '.js-tab-message-types .all-or-selected',
    myKeys: '.js-filters-my-keys',
    otherKeys: '.js-filters-other-keys',
  },

  displayConfCallFilter: function(hasControlCall) {
    var ccFilterToggle = this.ui.messagesTab.find('.js-filterctl-controlcall')

    if (hasControlCall) {
      ccFilterToggle.show()
    } else {
      ccFilterToggle.hide()
    }
  },

  onRender: function() {
    check.feature('controlCall').then(_.bind(this.displayConfCallFilter, this))

    this.collection.each(_.bind(this.onFilter, this))

    this.listenTo(
      this.collection,
      'change:selected',
      _.bind(this.onFilter, this)
    )
    this.listenTo(
      this.collection,
      'change:selected',
      _.bind(this.updateIcon, this)
    )
    this.listenTo(
      this.filtersGroup,
      'change:state',
      _.bind(this.updateIcon, this)
    )

    var $messagesFilters = this.ui.messagesTab.find('.filter-group')

    var setState = function($el, state) {
      var $mask = $el.next().find('.js-mask-filters')

      if (state === 'all') {
        $mask.removeClass('hidden')
        $el.find('option[value="all"]').prop('selected', true)
      } else {
        $mask.addClass('hidden')
        $el.find('option[value="filtered"]').prop('selected', true)
      }
    }

    // Set state for group
    _.each(
      this.filtersGroup.models,
      _.bind(function(filtersGroup) {
        var groupKey = filtersGroup.get('groupKey')
        var groupState = filtersGroup.get('state')

        switch (groupKey) {
          case 'filterRoutes':
            setState.call(this, this.ui.routesToggle, groupState)
            break

          case 'filterTypes':
            setState.call(this, this.ui.typesToggle, groupState)
            break
        }
      }, this)
    )

    // type filters
    $messagesFilters.on(
      'change',
      'input:not(.js-toggle-group)',
      _.bind(this.onCheckbox, this)
    )
    $messagesFilters.on(
      'change',
      'input.js-toggle-group',
      _.bind(this.onGroupCheckbox, this)
    )

    // Toggle on/off the entire type of filter group (routes/types)
    this.ui.typesToggle.on(
      'change',
      'select',
      _.bind(this.toggleFiltersGroup, this)
    )
    this.ui.routesToggle.on(
      'change',
      'select',
      _.bind(this.toggleFiltersGroup, this)
    )
  },

  // Custom
  // -------------------------------------------------------------------

  toggleFiltersGroup: function(e) {
    var $select = $(e.currentTarget)
    var $target = $select.closest('[data-tabGroup]')
    var groupKey = $target.attr('data-tabGroup')
    var model = this.filtersGroup.findWhere({ groupKey: groupKey })
    var state = $select.val()

    // add/remove mask
    var $mask = $select
      .closest('.all-or-selected')
      .next()
      .find('.js-mask-filters')

    if (state === 'all') {
      $mask.removeClass('hidden')
    } else {
      $mask.addClass('hidden')
    }

    model.set('state', state)
    model.save()
  },

  renderRouteFilters: function() {
    var $routesFilters = this.ui.routesTab.find('.filter-group')
    var $myKeys = this.ui.myKeys.empty()
    var $otherKeys = this.ui.otherKeys.empty()

    var myKeys = this.routes.where({ routeGroupKey: 'myKeys' })
    var otherKeys = this.routes.where({ routeGroupKey: 'restKeys' })

    _.each(
      this.groups.models,
      _.bind(function(group) {
        var $group = this.$(
          '[data-routeGroup="' + group.get('routeGroupKey') + '"]'
        )
        var $select = $group.find('.js-toggle-group')
        var state = group.get('state')

        if (state === 'on') {
          $select.find('option[value="on"]').prop('selected', true)
        } else {
          $select.find('option[value="off"]').prop('selected', true)
        }
      }, this)
    )

    if (myKeys.length) {
      _.each(myKeys, function(route) {
        var $route = buildRouteFilterString(route)
        $myKeys.append($route)
        return $route
      })
    } else {
      $myKeys.append('<em>No routing keys to display</em>')
    }

    if (otherKeys.length) {
      _.each(otherKeys, function(route) {
        var $route = buildRouteFilterString(route)
        $otherKeys.append($route)
        return $route
      })
    } else {
      $otherKeys.append('<em>No routing keys to display</em>')
    }

    var myState = this.groups.findWhere({ routeGroupKey: 'myKeys' })
    var restState = this.groups.findWhere({ routeGroupKey: 'restKeys' })

    $myKeys
      .closest('[data-routeGroup]')
      .find('.js-mask-group')
      .toggleClass('hidden', myState.get('state') === 'on')
    $otherKeys
      .closest('[data-routeGroup]')
      .find('.js-mask-group')
      .toggleClass('hidden', restState.get('state') === 'on')

    $routesFilters.on(
      'change',
      'input:not(.js-toggle-group)',
      _.bind(this.onRouteCheckbox, this)
    )
    $routesFilters.on(
      'change',
      '.js-toggle-group',
      _.bind(this.onRouteGroupCheckbox, this)
    )
  },

  toggleFilters: function(hide) {
    var $filters = this.ui.filters

    if ($filters.is(':visible')) {
      $filters.find('.is-new').removeClass('is-new')
      $filters.hide()

      vent.trigger('filters:render')
    } else if (!hide) {
      $filters.show()
    }
  },

  docOnClick: function(e) {
    var isChild = $(e.target).closest(this.$el).length

    if (!isChild) {
      this.toggleFilters(true)
    }
  },

  docOnKeydown: function(e) {
    if (e.which === 27) {
      this.toggleFilters(true)
    }
  },

  events: {
    'click .js-timeline-filter-tabs': 'clickTabs',
  },

  clickTabs: function(event) {
    var $el = $(event.target)
    var filters = $el.data('filters')

    if (filters) {
      $el.siblings().removeClass('current')
      $el.addClass('current')

      this.$('[class*=js-tab-]').addClass('hidden')
      this.$('.js-tab-' + filters).removeClass('hidden')
    }
  },

  updateIcon: function() {
    // var typeFiltering = this.collection.any(function(m) { return m.get('selected'); });

    var filtering = this.filtersGroup.any(function(m) {
      var state = m.get('state')

      return state === 'filtered'
    })

    vent.trigger('filters:applied', filtering)
  },

  onCheckbox: function(event) {
    var $check = $(event.currentTarget)
    var $target = $check.closest('[data-className]')
    var klass = $target.attr('data-className')
    var model = this.collection.findWhere({ className: klass })

    model.set('selected', !$check.is(':checked'), { fromcheck: true })
    model.save()
  },

  // Possibly create new region for filters
  onGroupCheckbox: function(event) {
    var $check = $(event.currentTarget)
    var $target = $check.closest('[data-className]')
    var $group = $target.closest('.filter-group')
    var notChecked = !$check.is(':checked')

    $group.find('ul > li').each(
      _.bind(function(i, el) {
        var $el = $(el)
        var $input = $el.find('input')
        var $label = $el.find('label')
        var model = this.collection.findWhere({
          className: $el.attr('data-className'),
        })

        if (notChecked) {
          $input.prop('disabled', true)
          $input.prop('checked', false)
          $label.addClass('is-disabled')
        } else {
          $input.prop('disabled', false)
          $input.prop('checked', true)
          $label.removeClass('is-disabled')
        }

        model.set('selected', notChecked, { fromcheck: true })
        model.save()
      }, this)
    )

    var model = this.collection.findWhere({
      className: $target.attr('data-className'),
    })

    model.set('selected', notChecked, { fromcheck: true })
    model.save()
  },

  onRouteCheckbox: function(e) {
    var $check = $(e.currentTarget)
    var $target = $check.closest('[data-route]')
    var routeKey = $target.attr('data-route')
    var model = this.routes.findWhere({ routeKey: routeKey })
    var state = $check.is(':checked') ? 'on' : 'off'

    model.set('state', state)
    model.save()
  },

  onRouteGroupCheckbox: function(e) {
    var $select = $(e.currentTarget)
    var $target = $select.closest('[data-routeGroup]')
    var routeGroupKey = $target.attr('data-routeGroup')
    var model = this.groups.findWhere({ routeGroupKey: routeGroupKey })
    var state = $select.val()
    var $mask = $target.find('.js-mask-group')

    $mask.toggleClass('hidden', state === 'on')

    model.set('state', state)
    model.save()
  },

  onFilter: function(model, collection, options) {
    if (options.fromcheck) {
      return
    }

    var klass = model.get('className')
    var selected = model.get('selected')
    var $check = this.$('[data-className=' + klass + '] input')
    var $group = $check
      .closest('.filter-group')
      .find('[data-className^="toggle"]')

    if (selected) {
      // filtering, no set
      $check.removeAttr('checked')
    } else {
      // not filtering, set
      $check.attr('checked', 'checked')
    }

    var notGroupChecked = !$group.find('input').is(':checked')

    // bail if group is disabled.
    if (notGroupChecked && _.isUndefined($check.attr('class'))) {
      $check.attr('disabled', true)
      $check.closest('label').addClass('is-disabled')
    }
  },
})
