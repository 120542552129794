// vendor
import $ from 'lib/jquery'
import Marionette from 'lib/marionette'
import _ from 'lib/underscore'

// lib

// var log = require('util/_console');
import teamsCollection from '../data/managers/groups'
import usersCollection from '../data/managers/users'
import vent from 'util/vent'
import Dropdown from '../views/autocomplete'
import TimelineChatTpl from 't/timeline/timeline-chat.tpl'
import DeliveryInsightsTimelineChatTpl from 't/timeline/delivery-insights-timeline-chat.tpl'

const config = window.VO_CONFIG

export default Marionette.ItemView.extend({
  // Overrides
  // -------------------------------------------------------------------

  initialize: function(opts) {
    _.bindAll(this, [
      'template',
      'onRender',
      'initDropdowns',
      'toggleQuickFilter',
      'toggleChatQuickFilter',
    ])
    this.type = opts.subtype
    this.quickFilterEnabled = opts.quickFilterEnabled
    this.username = config.auth.user.username
    this.containerId = opts.containerId // this is used to juggle events between dropdowns

    this.initializedAutocompleteContainers = []

    this.listenTo(vent, 'dropdowns:init', this.initDropdowns)

    this.listenTo(
      vent,
      'filters:applied',
      _.bind(function(filtering) {
        var $toggle = this.ui.toggle
        var $toggleOn = this.ui.toggleOn
        var $toggleOff = this.ui.toggleOff

        if (filtering) {
          $toggle.addClass('filtering')
          $toggleOn.show()
          $toggleOff.hide()
          $toggle.attr('title', 'Filters applied')
        } else {
          $toggle.removeClass('filtering')
          $toggleOn.hide()
          $toggleOff.show()
          $toggle.attr('title', 'No filters applied')
        }
      }, this)
    )
  },

  template: function() {
    if (this.quickFilterEnabled) {
      return DeliveryInsightsTimelineChatTpl
    } else {
      return TimelineChatTpl
    }
  },

  ui: {
    acTags: '.js-hashtag-dropdown',
    acUsersTeams: '.js-user-dropdown',
    acWrapper: '.js-timeline-autocomplete-parent',
    chatQuickFilter: '.chat-quickfilter',
    toggle: '.js-filter-toggle',
    toggleOn: '.filter-toggle-on',
    toggleOff: '.filter-toggle-off',
    quickFilter: '.quickfilter',
  },

  events: {
    'keypress .timeline-input textarea': function(e) {
      vent.trigger('filters:chat:keypress', this.type, e)
    },

    'keyup .timeline-input textarea': function(e) {
      vent.trigger('filters:chat:keyup', this.type, e)
    },

    'paste .timeline-input textarea': function(e) {
      vent.trigger('filters:chat:paste', this.type, e)
    },

    'click .js-filter-toggle': function(e) {
      e.stopPropagation()
      vent.trigger('filters:toggle')
    },

    'click .quickfilter': function(e) {
      e.stopPropagation()
      this.toggleQuickFilter()
    },

    'click .chat-quickfilter': function(e) {
      e.stopPropagation()
      this.toggleChatQuickFilter()
    },
  },

  onRender: function() {
    if (this.options.quickFilters) {
      const deliveryInsights = this.options.quickFilters
        .get('deliveryInsights')
        .get('state')
      const chat = this.options.quickFilters.get('chat').get('state')
      if (deliveryInsights === 'on') {
        this.quickFilterActive = false
        this.toggleQuickFilter('on')
      } else if (chat === 'on') {
        this.chatQuickFilterActive = false
        this.toggleChatQuickFilter('on')
      }
    }
  },

  // Custom
  // -------------------------------------------------------------------

  initDropdowns: function(msg) {
    // @SEE: https://victorops.atlassian.net/browse/VOP-5842
    //
    // This is a bit weird, but works for the moment. We essentially
    // have a kind of sloppy pub/sub model here, and the quick fix to
    // prevent autocomplete chat from breaking is to suppress events
    // if they aren't relevant, or if the dropdowns have already been
    // initialized for the container.
    var shouldNotRender = function(id) {
      var wrongContainer = msg.containerId !== id
      var alreadyRendered = _.includes(
        this.initializedAutocompleteContainers,
        id
      )

      return wrongContainer || alreadyRendered
    }

    if (_.bind(shouldNotRender, this)(this.containerId)) {
      return // bail
    }

    this.initializedAutocompleteContainers = this.initializedAutocompleteContainers.concat(
      this.containerId
    )

    /* eslint-disable */
    new Dropdown({
      /* eslint-enable */
      $dropdown: this.ui.acTags,

      dropdownOptions: msg.options,

      el: this.ui.acWrapper,

      lists: [
        {
          trigger: '#',
          className: 'pound-hashtag',
          collection: [
            { mainText: 'host' },
            { mainText: 'incident' },
            { mainText: 'service' },
          ],
          listHeading: 'Mention an item',
        },
      ],
    })

    $.when(usersCollection.deferredLoad, teamsCollection.deferredLoad).done(
      _.bind(function(usersCol, teamsCol) {
        var users = _.clone(usersCol.models)
        var teams = _.clone(teamsCol.models)

        users = _.map(users, function(user) {
          return {
            mainText: user.get('USER_ID'),
            subText: user.get('FIRST_NAME') + ' ' + user.get('LAST_NAME'),
            value: user.get('USER_ID'),
          }
        })

        teams = _.map(teams, function(team) {
          return {
            mainText: team.get('NAME'),
            subText: 'Team: ' + team.id,
            value: team.id,
          }
        })

        var userList = {
          trigger: '@',
          className: 'at-user',
          collection: users,
          listHeading: 'Mention a user:',
          iconClass: 'fas fa-user',
        }

        var teamList = {
          trigger: '@@',
          className: 'at-team',
          collection: teams,
          listHeading: 'Mention a team:',
          iconClass: 'fas fa-users',
        }

        this.autocompleteUsersTeams = new Dropdown({
          $dropdown: this.ui.acUsersTeams,

          dropdownOptions: msg.options,

          el: this.ui.acWrapper,

          hint: 'type @ or # for autocomplete options...',

          lists: [userList, teamList],
        })
      }, this)
    )
  },

  toggleQuickFilter: function() {
    if (this.quickFilterActive) {
      this.quickFilterActive = false
      this.ui.quickFilter.removeClass('active')
      vent.trigger(
        'reacttimeline:insightsQuickFilterActive',
        { insightsQuickFilter: false },
        { storage: this.options.quickFilters, state: 'off' }
      )
      vent.trigger('filters:render')
    } else {
      this.quickFilterActive = true
      this.ui.quickFilter.addClass('active')
      this.chatQuickFilterActive = false
      this.ui.chatQuickFilter.removeClass('active')
      vent.trigger(
        'reacttimeline:insightsQuickFilterActive',
        { insightsQuickFilter: true, chatQuickFilter: false },
        { storage: this.options.quickFilters, state: 'on' }
      )
      vent.trigger('filters:reset', [])
    }
  },

  toggleChatQuickFilter: function() {
    if (this.chatQuickFilterActive) {
      this.chatQuickFilterActive = false
      this.ui.chatQuickFilter.removeClass('active')
      vent.trigger(
        'reacttimeline:chatQuickFilter',
        { chatQuickFilter: false },
        { storage: this.options.quickFilters, state: 'off' }
      )
      vent.trigger('filters:render')
    } else {
      this.chatQuickFilterActive = true
      this.ui.chatQuickFilter.addClass('active')
      this.quickFilterActive = false
      this.ui.quickFilter.removeClass('active')
      vent.trigger(
        'reacttimeline:chatQuickFilter',
        { chatQuickFilter: true, insightsQuickFilter: false },
        { storage: this.options.quickFilters, state: 'on' }
      )
      vent.trigger('filters:reset', ['typechatnotify'])
    }
  },
})
