// vendor
import Marionette from 'lib/marionette'
import $ from 'jquery'

// lib
import server from '../../server'
import vent from 'util/vent'

export default Marionette.ItemView.extend({
  template: require('./templates/controlcall-end.tpl'),

  className: 'vo-modal-dialog',

  bindings: {
    '.js-controlcall-name': 'NAME',
  },

  events: {
    'click .js-cancel': 'destroy',
    'click .js-controlcall-end': 'endCall',
  },

  endCall: function() {
    var deferred = server.endControlCall(this.model.id)

    $.when(deferred).then(function(data) {
      server.mqueue.processAll(server.trans.translate(data))
    })

    vent.trigger('modal:small:empty')
  },

  render: function() {
    Marionette.ItemView.prototype.render.call(this)
    this.stickit()
  },
})
