// lib
import Marionette from 'lib/marionette'

// util
import spToHuman from 'util/statuspage_to_human'

export default Marionette.ItemView.extend({
  className: function() {
    return this.model.get('group_id')
      ? 'statuspage-component-child'
      : 'statuspage-component-parent'
  },

  // Override
  // --------------------------------------------------------------------

  template: function(component) {
    // Format for humans
    component.status = spToHuman(component.status)

    return require('t/statuspage/notifications/component.tpl')(component)
  },

  // Custom
  // --------------------------------------------------------------------
})
