import React, { Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDotCircle } from '@fortawesome/fontawesome-pro-solid'
import { faCircle } from '@fortawesome/fontawesome-pro-light'
import { Button, PickerMultiSelect } from '@victorops/victory'
import { hideModal, maintenanceStart } from 'components/store/actions'
import {
  makeGetMaintenance,
  makeGetRoutesWithPolicies,
} from 'components/store/selectors'

function mapStateToProps(state, ownProps) {
  const getRoutesWithPolicies = makeGetRoutesWithPolicies()
  const maintenance = makeGetMaintenance()(state)
  const hasActiveGlobalInstance = !maintenance
    .filter((val, key) => val.get('isGlobal'))
    .isEmpty()

  return {
    hasActiveGlobalInstance,
    maintenance,
    routesWithPolicies: getRoutesWithPolicies(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    hideModal: () => dispatch(hideModal()),
    startMaintenanceMode: payload => dispatch(maintenanceStart(payload)),
  }
}

class StartModal extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      errorMessage: '',
      filter: '',
      hasActiveGlobalInstance: false,
      isAtRoutingKeyLimit: false,
      isGlobal: true,
      isSubmitting: false,
      maxExceeded: false,
      purpose: 'Maintenance Mode',
      selectedOptions: [],
    }
  }

  componentDidMount = () => {
    if (this.props.maintenance.size >= 500) {
      this.setState({
        errorMessage: `You have reached the maximum number of Maintenance Mode
        instances you can have active at one time. You must end an instance to start a new one.`,
        isSubmitting: false,
        maxExceeded: true,
      })
    }
  }

  onPurposeChange = e => {
    this.setState({ purpose: e.target.value })
  }

  handleFilterChange = next => {
    this.setState({ filter: next })
  }

  handleSelectedChange = next => {
    if (this.state.selectedOptions.length < 100 || next.length < 101) {
      this.setState({ selectedOptions: next, isAtRoutingKeyLimit: false })
    } else {
      this.setState({ isAtRoutingKeyLimit: true })
    }
  }

  handleError = e => {
    if (e.status === 420) {
      this.setState({
        isSubmitting: false,
        errorMessage:
          'There can only be 500 active maintenance modes at a time',
      })
    } else {
      this.setState({
        isSubmitting: false,
        errorMessage: (
          <span>
            An error occured while attempting to start Maintenance Mode. Please
            try again or contact support via live chat, or open a case on the{' '}
            <a href='https://login.splunk.com/'>Splunk Support Portal</a>.
          </span>
        ),
      })
    }
  }

  handleManageGMM = () => {
    const targetUrl = new URL(window.location.href)
    targetUrl.pathname = `/dash/${window.VO_CONFIG.orgslug}`
    targetUrl.hash = '/maintenance-mode'
    window.open(targetUrl.href, '_blank')
  }

  handleSuccess = () => {
    window.app.toaster.success('Maintenance Mode Started')
    this.props.hideModal()
  }

  handleSubmit = () => {
    const keyNames = this.state.selectedOptions.map(el => {
      return el.label
    })

    if (this.state.isGlobal && this.props.hasActiveGlobalInstance) {
      this.setState({
        errorMessage: 'There can only be one global maintenance mode at a time',
      })
      return
    }

    const payload = {
      error: this.handleError,
      payload: {
        type: 'RoutingKeys',
        names: keyNames,
        purpose: this.state.purpose,
      },
      success: this.handleSuccess,
    }

    this.props.startMaintenanceMode(payload)
    this.setState({
      isSubmitting: true,
      errorMessage: '',
    })
  }

  maxMaintenanceModesExceeded() {
    return (
      <Fragment>
        <p className='alert alert-danger'>{this.state.errorMessage}</p>
        <div className='maintenance-mode--modal_form_btn-group'>
          <Button
            content='Cancel'
            type='btn-secondary btn-sm margin-right-8'
            clickHandler={this.props.hideModal}
            dataExt='gmm-start-modal--cancel'
          />
          <Button
            content='Manage Maintenance Mode'
            type='btn-outline-primary btn-sm'
            clickHandler={this.handleManageGMM}
            dataExt='gmm-start-modal--manage'
          />
        </div>
      </Fragment>
    )
  }

  getRoutingKeyLimitText = () => {
    return this.state.isAtRoutingKeyLimit ? (
      <div className='error-text margin-bottom-4'>
        Maximum number of Routing Keys reached.
      </div>
    ) : null
  }

  getRoutingKeyPicker = () => {
    return this.state.isGlobal ? null : (
      <div>
        <div className='maintenance-mode--modal_form_sub-header'>
          Select Routing Key(s)
        </div>
        {this.getRoutingKeyLimitText()}
        <PickerMultiSelect
          filter={this.state.filter}
          onFilterChange={this.handleFilterChange}
          onSelectedChange={this.handleSelectedChange}
          options={this.props.routesWithPolicies}
          placeholder={''}
          selectedOptions={this.state.selectedOptions}
          data-test-id='gmm-start-modal--maintenance-mode-routing-keys'
        />
      </div>
    )
  }

  render() {
    return this.state.maxExceeded ? (
      this.maxMaintenanceModesExceeded()
    ) : (
      <Fragment>
        <p className='alert alert-info'>
          Paging will be muted for any new triggered incidents for the Routing
          Key(s) you select. Paging will continue for incidents that are already
          in progress.
        </p>
        {this.state.errorMessage ? (
          <p className='alert alert-danger'>{this.state.errorMessage}</p>
        ) : null}
        <div className='maintenance-mode--modal_form'>
          <div className='maintenance-mode--modal_form_sub-header'>Purpose</div>
          <div>
            <input
              className='maintenance-mode--modal_form_input'
              data-ext='maintenance-mode--modal_purpose'
              maxLength='50'
              name='purpose'
              onChange={this.onPurposeChange}
              type='text'
              value={this.state.purpose}
            />
          </div>

          <button
            className='reset-style hoverable'
            onClick={() =>
              this.setState({
                isGlobal: true,
                selectedOptions: [],
              })
            }
            type='button'
            data-test-id='gmm-start-modal--mute-paging-all'
          >
            {this.state.isGlobal ? (
              <FontAwesomeIcon className='active' icon={faDotCircle} />
            ) : (
              <FontAwesomeIcon className='inactive' icon={faCircle} />
            )}
            Mute paging for ALL Routing Keys
          </button>

          <br />

          <button
            className='reset-style hoverable'
            onClick={() => this.setState({ isGlobal: false })}
            type='button'
            data-test-id='gmm-start-modal--mute-routing-keys'
          >
            {this.state.isGlobal ? (
              <FontAwesomeIcon className='inactive' icon={faCircle} />
            ) : (
              <FontAwesomeIcon className='active' icon={faDotCircle} />
            )}
            Mute paging for select Routing Key(s)
          </button>

          {this.getRoutingKeyPicker()}

          <div className='maintenance-mode--modal_form_btn-group'>
            <Button
              content='Cancel'
              type='btn-secondary btn-sm margin-right-8'
              clickHandler={this.props.hideModal}
            />
            <Button
              content='Start Maintenance Mode'
              type='btn-outline-primary btn-sm'
              clickHandler={this.handleSubmit}
              dataExt='gmm-start-modal--start-maintenance-mode'
              disabled={
                (!this.state.isGlobal && !this.state.selectedOptions.length) ||
                this.state.isSubmitting ||
                !this.state.purpose ||
                this.props.maintenance.size >= 500
              }
            />
          </div>
        </div>
      </Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StartModal)
