// lib
import Marionette from 'lib/marionette'
import _ from 'vendor/lodash'

// util
import vent from 'util/vent'

// views
import StatusPageIncident from './incident'
import StatusPageComponent from './component'

// data
import SPCompColl from '../../../data/collections/statuspage/components'

export default Marionette.LayoutView.extend({
  // Override
  // --------------------------------------------------------------------

  template: require('t/statuspage/notifications/notifications.tpl'),

  regions: {
    incidentsRegion: '#statuspage-incidents',

    componentsRegion: '#statuspage-components',
  },

  initialize: function(options) {
    this.components = options.components
    this.incidents = options.incidents

    // Just effect me up
    this.pIncidents = this.incidents.fetch()
    this.pComponents = this.components.fetch()
  },

  events: {
    'click .js-create-incident': function() {
      vent.trigger('statuspage:container:show', 'incident')
    },

    'click .js-edit-components': function() {
      vent.trigger('statuspage:container:show', 'components')
    },
  },

  onShow: function() {
    var showIncidents = _.curry(function(error, incidentsCollection) {
      // Error view
      if (error) {
        return this.incidentsRegion.show(
          new Marionette.ItemView({
            template: _.template('Unable to fetch incidents. ⊙▂⊙'),
          })
        )
      }

      // Regular view
      this.incidentsRegion.show(
        new Marionette.CollectionView({
          collection: incidentsCollection,

          childView: StatusPageIncident,

          // Empty view
          isEmpty: function() {
            return (
              error || (!incidentsCollection && incidentsCollection.isEmpty())
            )
          },

          emptyView: Marionette.ItemView.extend({
            template: function() {
              return 'You have no active incidents. d=(´▽｀)=b'
            },
          }),
        })
      )
    })

    var showComponents = _.curry(function(error, componentsCollection) {
      // Error view
      if (error) {
        return this.componentsRegion.show(
          new Marionette.ItemView({
            template: _.template('Unable to fetch components. Σ(▼□▼メ)'),
          })
        )
      }

      // Regualr view
      this.componentsRegion.show(
        new Marionette.CollectionView({
          collection: componentsCollection,

          childView: StatusPageComponent,

          // Empty view
          isEmpty: function() {
            return (
              error || (!componentsCollection && componentsCollection.isEmpty())
            )
          },

          emptyView: Marionette.ItemView.extend({
            template: function() {
              return 'You have no degraded components. “ヽ(´▽｀)ノ”'
            },
          }),
        })
      )
    })

    this.pIncidents
      .then(
        function() {
          return this.incidents
        }.bind(this)
      )
      .done(showIncidents(null).bind(this))
      .fail(showIncidents.bind(this))

    this.pComponents
      .then(
        function() {
          return this.components
        }.bind(this)
      )
      .then(function(components) {
        return new SPCompColl(
          components.filter(function(comp) {
            return comp.get('status') !== 'operational'
          })
        )
      })
      .done(showComponents(null).bind(this))
      .fail(showComponents.bind(this))
  },

  // Custom
  // --------------------------------------------------------------------
})
