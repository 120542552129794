import timeline from 'components/__entry'

// vendor
var $ = require('lib/jquery')
var Marionette = require('lib/marionette')
var _ = require('lib/underscore')
require('util/jquery-plugins')

// lib
var server = require('../server').default
var stringUtil = require('util/String').default
var vent = require('util/vent').default
var mainTimeline = require('t/timeline/deprecated-r-timeline.tpl')
var incidentTimeline = require('t/timeline/deprecated-r-incident-timeline.tpl')

export default Marionette.ItemView.extend({
  // Overrides
  // -------------------------------------------------------------------

  initialize: function(options) {
    this.options = options

    _.bindAll(this, [
      'getTemplate',
      '_showTimeline',
      'onShow',
      'onDestroy',
      'bindListeners',
      'loadOld',
      'doLoad',
      'forceDoLoad',
      'getFilters',
      'changeFilter',
      'onKeyPressInput',
      'onKeyupInput',
      'sendChat',
      'appendTagsToMessage',
      'enableChat',
      'disableChat',
      'onPasteInput',
    ])
    // timeline options
    this.ROOM_ID = options.ROOM_ID
    this.type = options.subtype
    this.tags = this.model.tags
    this.reactDomNode = options.reactDomNode
    this.disableFilters = options.disableFilters
    this.disableInfiniteScroll = options.disableInfiniteScroll
    this.isPrimary = options.isPrimary
    this.styles = options.styles || {}

    // type listener
    this.filters = options.filterTypes
    this.filtersGroup = options.filtersGroup

    // bind listeners
    this.bindListeners()

    var initialFilters = this.filters.reduce(function(filtersObject, model) {
      filtersObject[model.get('className')] = model.get('selected')
      return filtersObject
    }, {})

    if (this.filtersGroup && this.filtersGroup.get('filterTypes')) {
      var filterTypes = this.filtersGroup.get('filterTypes').get('state')

      initialFilters['filter-types'] = filterTypes === 'filtered'
    }

    vent.trigger('reacttimeline:filter', initialFilters)
  },

  getTemplate: function() {
    return this.type === 'incident' ? incidentTimeline : mainTimeline
  },

  ui: {
    content: '.js-timeline-anchor',
    last: '.js-timeline-bottom',
    stream: '.js-timeline',
  },

  _showTimeline: function() {
    timeline.attach('timeline', {
      roomId: this.ROOM_ID,
      reactDomNode: this.reactDomNode,
      disableFilters: this.disableFilters,
      disableInfiniteScroll: this.disableInfiniteScroll,
      isPrimary: this.isPrimary,
    })

    this.renderQueue = {}
    this.renderScheduled = false

    if (this.type === 'primary') {
      this.forceDoLoad()
    }
  },

  onShow: function() {
    this._showTimeline()
  },

  onDestroy: function() {
    timeline.detach('timeline', {
      roomId: this.ROOM_ID,
      reactDomNode: this.reactDomNode,
    })
  },

  // Custom
  // -------------------------------------------------------------------

  bindListeners: function() {
    // chat
    this.listenTo(
      vent,
      'filters:chat:keypress',
      _.bind(function(room, e) {
        if (this.type === room) {
          this.onKeyPressInput(e)
        }
      }, this)
    )

    this.listenTo(
      vent,
      'filters:chat:keyup',
      _.bind(function(room, e) {
        if (this.type === room) {
          this.onKeyupInput(e)
        }
      }, this)
    )

    this.listenTo(
      vent,
      'filters:chat:paste',
      _.bind(function(room, e) {
        if (this.type === room) {
          this.onPasteInput(e)
        }
      }, this)
    )

    // change room
    this.listenTo(this.filters, 'change:selected', this.changeFilter)

    // listen to server events
    this.listenTo(server, 'socket:connected', this.enableChat)
    this.listenTo(
      server,
      'socket:disconnected socket:timeout',
      this.disableChat
    )

    // global type filters state
    this.listenTo(
      vent,
      'filters:toggleAllTypes',
      _.bind(function(room) {
        var group, cb // init

        if (this.type === room) {
          group = this.filtersGroup.findWhere({ groupKey: 'filterTypes' })
          cb = this.changeFilter

          if (group.get('state') === 'all') {
            vent.trigger('reacttimeline:filter', { 'filter-types': false })

            this.filters.each(function(model) {
              cb(model, false)
            })
          } else {
            vent.trigger('reacttimeline:filter', { 'filter-types': true })

            this.filters.each(function(model) {
              cb(model, model.get('selected'))
            })
          }
        }
      }, this)
    )
  },

  // TODO: we could and should move this loading stuff to react even before the filter re-write
  loadOld: function() {
    if (this.loading || this.nomore) {
      return
    }

    this.loading = true

    var self = this
    var request = server.requestHistory(this.ROOM_ID, 50)

    request.done(function(dataIn) {
      self.loading = false

      var retlist = dataIn.PAYLOAD.TIMELINE_LIST

      if (retlist && retlist.length === 0) {
        self.nomore = true
      }
    })
  },

  doLoad: function() {
    this.loadOld()
  },

  // @TODO
  // this should not actually start a timer until after first payload...
  // so, history request should emit an event that we're subscribed to,
  // which out to start the timer. This will prevent breakage when
  // network is degraded.
  forceDoLoad: function() {
    var self = this

    // Globals, sup
    clearTimeout(window.vops_forceDoLoad)

    window.vops_forceDoLoad = setTimeout(function() {
      self.loadOld()
    }, 1332)
  },

  getFilters: function(filters) {
    var stateModel = this.filtersGroup.findWhere({ groupKey: 'filterTypes' })

    if (stateModel.get('state') === 'filtered') {
      return _.some(filters, function(f) {
        return f.get('selected')
      })
    } else {
      return false
    }
  },

  changeFilter: function(filter, selected) {
    var className = filter.get('className')
    var $items = this.$el.find('.' + className)

    $items.toggleClass('filtered', selected)

    vent.trigger('reacttimeline:filter', { [className]: selected })
    this.trigger('filters:flush')
  },

  // send chat when enter is pressed
  onKeyPressInput: function(event) {
    var $target = $(event.currentTarget)

    if (event.which === 13 && event.shiftKey) {
      event.preventDefault()

      $target.pasteIntoInput('\n')
    } else if (event.which === 13) {
      event.preventDefault()

      this.sendChat($target)

      $target.removeClass('multiline-input')
    }

    if (stringUtil.hasNewlines($target.val())) {
      $target.addClass('multiline-input')
    }
  },

  onKeyupInput: function(e) {
    var $target = $(e.currentTarget)

    if (!stringUtil.hasNewlines($target.val()) && !$.textWraps($target)) {
      $target.removeClass('multiline-input')
    }

    $target.css('height', 'auto')
    $target.css('height', e.target.scrollHeight + 'px')
  },

  sendChat: function($input) {
    var msg = $input.val().trim()

    if (msg !== '') {
      server.sendChat(this.ROOM_ID, this.appendTagsToMessage(msg))
      $input.val('')
      $input.css('height', 'auto')
    }
  },

  appendTagsToMessage: function(msg) {
    if (_.isArray(this.tags)) {
      msg =
        msg +
        ' ' +
        _.map(this.tags, function(t) {
          return '#' + t
        }).join(' ')
    }

    return msg
  },

  enableChat: function() {
    this.$('.timeline-input textarea')
      .prop('disabled', false)
      .attr('title', '')
      .off('mouseenter', function(e) {
        e.stopPropagation()
      })
      .off('mouseleave', function(e) {
        e.stopPropagation()
      })
  },

  disableChat: function() {
    this.$('.timeline-input textarea')
      .prop('disabled', true)
      .attr('title', 'Socket not connected - chat is unavailable.')
      .on('mouseenter', function(e) {
        e.stopPropagation()
      })
      .on('mouseleave', function(e) {
        e.stopPropagation()
      })
  },

  onPasteInput: function(e) {
    var $input = $(e.currentTarget)

    // Defer is so that cross-browser, we actually capture what is
    // pasted.
    _.defer(function() {
      var val = $input.val()

      if (stringUtil.hasNewlines(val) || $.textWraps($input)) {
        $input.addClass('multiline-input')
      }
    })
  },
})
