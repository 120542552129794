// lib
import Marionette from 'lib/marionette'
import moment from 'lib/moment'
import vent from 'util/vent'

// util
import spToHuman from 'util/statuspage_to_human'

export default Marionette.ItemView.extend({
  // Override
  // --------------------------------------------------------------------

  events: {
    'click .js-edit-incident': function() {
      vent.trigger('statuspage:container:show', 'incident', this.model)
    },
  },

  template: function(incident) {
    // Formatting for humans.
    incident.status = spToHuman(incident.status)
    incident.created_at = moment(incident.created_at).format(
      'MMM Do, YYYY h:mm:ss z'
    )
    incident.updated_at = moment(incident.updated_at).format(
      'MMM Do, YYYY h:mm:ss z'
    )

    return require('t/statuspage/notifications/incident.tpl')(incident)
  },

  // Custom
  // --------------------------------------------------------------------
})
