// vendor
import _ from 'lib/underscore'
import Marionette from 'lib/marionette'
import 'lib/stickit'
import $ from 'jquery'

// lib
import ControlCall from '../../data/models/controlcall'
import server from '../../server'
import vent from 'util/vent'
var config = window.VO_CONFIG

var Member = Marionette.ItemView.extend({
  events: {
    'click .js-member-remove': 'onClick',
  },

  tagName: 'li',

  template: require('./templates/member.tpl'),

  onClick: function() {
    this.trigger('action:remove')
  },
})

var MembersCollection = Marionette.CollectionView.extend({
  initialize: function() {
    this.listenTo(this.collection, 'add', function(member) {
      vent.trigger('controlcall:invite', member, true)
    })

    this.listenTo(this.collection, 'remove', function(member) {
      vent.trigger('controlcall:invite', member, false)
    })
  },

  className: 'control-call-list',

  childView: Member,

  childEvents: {
    'action:remove': 'onRemoveMember',
  },

  tagName: 'ul',

  onRemoveMember: function(child) {
    this.collection.remove(child.model)
  },
})

export default Marionette.LayoutView.extend({
  regions: {
    members: '.js-members',
  },

  template: require('./templates/controlcall-init.tpl'),

  className: 'vo-modal-dialog',

  initialize: function(options) {
    this.model = new ControlCall()
    this.actionList = options.actionList

    this.model.set(
      'INITIATOR_FULLNAME',
      config.auth.user.firstName + ' ' + config.auth.user.lastName
    )
    this.model.set('INITIATOR', config.auth.user.username)

    this.actionList.each(
      _.bind(function(user) {
        this.changeInviteStatus(user, true)
      }, this)
    )

    this.listenTo(vent, 'controlcall:invite', this.changeInviteStatus)
  },

  ui: {
    step1: '.js-setup-step1',
    step2: '.js-setup-step2',
    error: '.js-setup-error',
    notifying: '.js-setup-notifying',
    nameInput: '.js-call-name',
    startCallForm: '.js-start-call-form',
    noInvitedUsers: '.js-no-invited',
  },

  bindings: {
    '.js-call-name': 'NAME',
    '.js-validation-error': 'val_error',
  },

  events: {
    'click .js-cancel': 'destroy',
    'click [data-dismiss="modal"]': 'destroy',
    'click .js-start-call': 'initCall',
    'blur .js-call-name': 'unsetError',
    'submit form': function(e) {
      e.preventDefault()
    },
  },

  onRender: function() {
    vent.trigger('controlcall:modal:show')

    this.stickit()

    this.members.show(new MembersCollection({ collection: this.actionList }))
  },

  onDestroy: function() {
    vent.trigger('controlcall:modal:close')
  },

  unsetError: function() {
    var $el = this.ui.nameInput

    if ($.trim($el.val()).length) {
      $el.removeClass('input-invalid')
    }
  },

  initCall: function(e) {
    var ui = this.ui
    var forceStart = $(e.currentTarget).data('forceStart') // start the call without inviting any users
    var participantIds = this.model.get('PARTICIPANTS').pluck('USER_ID')

    var initCallback = _.bind(function(data) {
      var call = data.PAYLOAD.CONF_CALL

      this.model.set(call)
      var evs = server.trans.translate(data)
      server.mqueue.processAll(evs)
    }, this)

    var initFail = function(data) {
      ui.step2.addClass('hidden')
      ui.error.removeClass('hidden')
      ui.error.find('.js-error-msg').html(data)
    }

    if (!this.model.get('NAME')) {
      ui.nameInput.addClass('input-invalid')
      return
    }

    if (!forceStart && participantIds.length < 2) {
      ui.startCallForm.addClass('hidden')
      ui.noInvitedUsers.removeClass('hidden')
      return
    }

    var promise = server.initControlCall(
      this.model.get('NAME'),
      participantIds,
      initCallback,
      initFail
    )

    ui.step1.addClass('hidden')
    ui.step2.removeClass('hidden')
    ui.noInvitedUsers.addClass('hidden')

    if (forceStart) {
      ui.startCallForm.removeClass('hidden')
    }

    $.when(promise).then(function(data) {
      if (data.STATE === 'FAILED') {
        initFail(
          '<br>An error occurred while trying to set up the Control Call. Please try again later.'
        )
      } else {
        ui.notifying.removeClass('invisible')
        _.delay(function() {
          vent.trigger('modal:form:empty')
        }, 2500)
      }
    })
  },

  changeInviteStatus: function(user, status) {
    if (status) {
      this.addUser(user)
    } else {
      this.removeUser(user)
    }
  },

  addUser: function(user) {
    this.model.get('PARTICIPANTS').add(user)
  },

  removeUser: function(user) {
    this.model.get('PARTICIPANTS').remove(user)
  },
})
