// vendor
import Marionette from 'marionette'
import _ from 'lodash'

// lib
import log from 'util/_console'

export default Marionette.ItemView.extend({
  className: 'rolodex-loading',

  template: function() {
    return '<img class="js-loading " src="/static/img/spinner.gif" />'
  },

  onDestroy: function() {
    clearTimeout(this.swapTimer)
  },

  onRender: function() {
    log.info('rolodex/loading:render')

    var swapEl = function() {
      this.$el.empty()
      this.$el.html('<span class="rolodex-empty">Nothing to see...</span>')
    }

    this.swapTimer = _.delay(_.bind(swapEl, this), 3000)
  },
})
