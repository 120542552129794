// vendor
import Marionette from 'lib/marionette'
import _ from 'lib/underscore'
import $ from 'jquery'

// lib
import data from '../data'
import vent from 'util/vent'

export default Marionette.ItemView.extend({
  className: 'user-invite-mode',
  initialize: function(options) {
    this.$html = $(options.template.html())
  },
  render: function() {
    var self = this
    var $html = this.$html
    var $list = $html.find('[class*=js-contactlist]')
    var $items = $list.find('> li')

    $items.on('click change', self.selectUser)

    _.each($items, function(item, index) {
      var $item = $(item)

      if (index === 0) {
        $item.addClass('is-selected')
        $item
          .find('.js-controlcall-checkbox')
          .trigger('change')
          .on('click', function(e) {
            e.preventDefault()
          })
        $item.off('click')
      }
    })

    $html.find('.js-controlcall').addClass('disabled')

    self.$el.append($html)
  },
  selectUser: function(e) {
    // change event bubbles up from child checkbox
    var $item = $(e.currentTarget)
    var $check = $item.find('.js-controlcall-checkbox')
    var cid = $item.data('cid')
    var newState = !$check.prop('checked')

    $item.toggleClass('is-selected', newState)
    $check.prop('checked', newState)
    vent.trigger('controlcall:invite', data.users.get(cid), newState)
  },
})
