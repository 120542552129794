// grunticon
// import '../../icon/output/grunticon.loader'

// vendor
import $ from 'jquery'
import Backbone from 'lib/backbone'
import Marionette from 'lib/marionette'
import Radio from 'backbone.radio'
import RxJS from 'rxjs'
import _ from 'lib/underscore'
import moment from 'vendor/moment'
import 'vendor/bootstrap.dropdown'
import Toastr from 'vendor/jquery.toastr'

// lib
import ModalRegion from '../lib/modalregion'
import RolodexContainer from './views/rolodex/container'
import check from 'util/check'
import data from './data'
import server from './server'
import vent from 'util/vent'
import xlog from 'util/extendedLog'
import notificationsFor from './notifications'
import newApp from '../components/__entry'
import debug from 'debug'

import socket from 'components/socket'
import store from 'components/store/app-timeline'

import BannerContainer from './views/banners/container'
import ControlCallInit from './views/modals/controlcall-init'
import Prompt from './views/statuspage/modals/prompt'
import SPCompColl from './data/collections/statuspage/components'
import SPCompManager from './data/managers/third_party/statuspage/components'
import SPContainer from './views/statuspage/modals/container'
import SPIncManager from './data/managers/third_party/statuspage/incidents'
import SPIncidentColl from './data/collections/statuspage/incidents'
import SPNotifcationsView from './views/statuspage/dropdowns/notifications'
import UserInvite from './views/user-invite'
import applicationTpl from 't/application.tpl'
import _paneState from './data/collections/pane-switcher/view.pane-switcher'
import Reauth from './views/modals/reauth'
import Reload from './views/modals/reload'
import getRouteKeys from 'util/route_keys'
import routerFactory from './router'
import IncidentCounter from './views/incidentcounter'
import TimelineContainer from './views/timeline-container'
import './data/models/model.helpers'

import { maintenanceUpdate, toggleOfflineMode } from 'components/store/actions'
import * as Sentry from '@sentry/react'
import environment from 'util/env'
import { logMessage } from '../util/monitoringService'

const config = window.VO_CONFIG
const Observable = RxJS.Observable

global.VO_FOCUSED = true
global.onfocus = () => {
  global.VO_FOCUSED = true
}
global.onblur = () => {
  global.VO_FOCUSED = false
}

// On page load ask the user if they want desktop notifications.

if (global.Notification && global.Notification.permission !== 'granted') {
  global.Notification.requestPermission()
}

notificationsFor(
  config.auth.user.username,
  Observable.fromEvent(server, 'notify:incidents')
)
  .filter(() => !window.VO_FOCUSED) // Don't show notifications when the tab is active
  .subscribe(function(incidents) {
    const notificationOptions = tag => ({
      options: {
        tag,
        // NOTE: This icon property only works in IE.
        //       https://github.com/ttsvetko/HTML5-Desktop-Notifications#api-documentation
        icon: {
          x32: '/static/img/vo-logo-graphic-32x32.ico',
          x16: '/static/img/vo-logo-graphic-16x16.ico',
        },
      },
    })

    // this is if the incidents got rolled up into one batch
    if (global.Notification && incidents.count) {
      return new Notification(
        `${incidents.count} new incident updates`,
        notificationOptions('batched-notification')
      )
    }

    // this is for single incident notifications.
    if (global.Notification) {
      incidents.forEach(function(incident) {
        const incidentName = incident.ENTITY_DISPLAY_NAME || incident.ENTITY_ID

        return new Notification(
          `${incident.CURRENT_ALERT_PHASE}: ${incidentName}`,
          notificationOptions(incident.ENTITY_ID)
        )
      })
    }
  })

// ========================================================================
Sentry.init({
  dsn:
    'https://7f747f134ee1427885aa921a6a1293da@o378897.ingest.sentry.io/5673734',
  environment,
})

Sentry.setUser({
  username: config.auth.user.username,
  org: config.auth.org.name,
})

Marionette.Application.prototype._initChannel = function() {
  this.channelName = _.result(this, 'channelName') || 'global'
  this.channel = _.result(this, 'channel') || Radio.channel(this.channelName)
}

var app = new Marionette.Application()
var $body = $('body')

function setContainerWidths() {
  var $people = $body.find('.l-pane-people')
  var $right = $body.find('.l-pane-group--right')

  var width = $body.width()

  if (width <= 1400) {
    $people.css({
      width: '280px',
    })

    $right.css({
      width: `${width - 280}px`,
      left: $people.width(),
    })
  } else {
    $people.css({
      width: '20%',
    })

    $right.css({
      width: '80%',
      left: '20%',
    })
  }
}

app.addRegions({
  headerLeft: '.header-segment.header-left',

  headerCenter: '.header-segment.header-center',

  headerRight: '.header-segment.header-right',

  incident: '#incidentdetails',

  person: '#contactdetails',

  modalform: {
    selector: '#js-modal-form',
    regionClass: ModalRegion,
  },

  modalreg: {
    selector: '#js-modal',
    regionClass: ModalRegion,
  },

  modalsmall: {
    selector: '#js-modal-small',
    regionClass: ModalRegion,
  },

  rolodex: '.js-rolodex-region',

  statusPageNotifications: '.js-statuspage-notifications',

  timeline: '.js-primary-timeline',

  userInvite: {
    selector: '.js-user-invite-mode',
  },

  incidentsOldContainer: '#incidents',
})

// Modal Region
// -----------------------------------------------------------------------

app.listenTo(vent, 'modal:reg:show', function(view) {
  app.modalreg.show(view)
})
app.listenTo(vent, 'modal:reg:empty', function() {
  app.modalreg.empty()
})

// Modal Small
// -----------------------------------------------------------------------

app.listenTo(vent, 'modal:small:show', function(view) {
  app.modalsmall.show(view)
})
app.listenTo(vent, 'modal:small:empty', function() {
  app.modalsmall.empty()
})

// Modal Form
// -----------------------------------------------------------------------

app.listenTo(vent, 'modal:form:show', function(view) {
  app.modalform.show(view)
})
app.listenTo(vent, 'modal:form:empty', function() {
  app.modalform.empty()
})

// User Invite
// -----------------------------------------------------------------------

app.listenTo(vent, 'user_invite:show', function(view) {
  app.userInvite.show(view)
})
app.listenTo(vent, 'user_invite:empty', function() {
  app.userInvite.empty()
})

// StatusPage
// -----------------------------------------------------------------------

app.listenTo(vent, 'statuspage:container:show', function(state, incident) {
  var container = new SPContainer({ state: state, incident: incident })

  vent.trigger('modal:form:show', container)
})

app.listenTo(vent, 'statuspage:container:empty', function() {
  vent.trigger('modal:form:empty')
})

app.listenTo(vent, 'statuspage:prompt:show', function() {
  vent.trigger('modal:small:show', new Prompt())
})

app.listenTo(vent, 'statuspage:prompt:empty', function() {
  vent.trigger('modal:small:hide')
})

// Control call
// -----------------------------------------------------------------------

app.listenTo(vent, 'controlcall:initCreate', function() {
  var list = new Backbone.Collection()

  list.add(data.users.get(config.auth.user.username))

  // Change rolodex state
  vent.trigger('actionlist:show', list)

  // Show modal
  var cc = new ControlCallInit({ actionList: list })
  vent.trigger('modal:form:show', cc)

  $body.find('.l-pane-people').addClass('pane-selectable')

  // Destroy stuff when done
  app.listenToOnce(vent, 'controlcall:modal:close', function() {
    vent.trigger('actionlist:hide')
    $body.find('.l-pane-people').removeClass('pane-selectable')
  })
})

app.listenTo(vent, 'banner:show', function(banner, options) {
  // need better scheduling here... these listeners should need to be
  // debounced or something...

  if (banner === 'maintenance') {
    $body.find('.js-enter-maintenance').addClass('very-hidden')
    $body.find('.js-exit-maintenance').removeClass('very-hidden')
  }

  // new notifications container
  newApp.attach('notificationsContainer', { banner })

  // old notifications container
  var bannerView = app.bannerView || new BannerContainer()
  bannerView.addView(banner, options)
  app.bannerView = bannerView
})

app.listenTo(vent, 'banner:hide', function(banner, id) {
  if (banner === 'maintenance') {
    $body.find('.js-enter-maintenance').removeClass('very-hidden')
    $body.find('.js-exit-maintenance').addClass('very-hidden')
  }
  if (app.bannerView) {
    app.bannerView.removeView(banner, id)
  }
})

var updateStatPageIcon = function(spIncMan, spCompMan) {
  var $spIcon = $('.js-statuspage-notifications-toggle')

  $spIcon.dropdown()

  if ($spIcon && $spIcon.length > 0) {
    $spIcon.removeClass('statuspage-icon-problem')
    if (spIncMan.isOkay() === false || spCompMan.isOkay() === false) {
      $spIcon.addClass('statuspage-icon-problem')
    }
  }
  // icon hidden by default - now we have confirmed feature flag and
  // successful integration, so show it
  $spIcon.closest('li').removeClass('hidden')
}

var displayStatusPage = function(hasStatusPage) {
  var spIncMan, spCompMan // init

  // This just checks the feature flag is set
  if (hasStatusPage) {
    // Subscribe to changes in integrations.statuspage to make sure integration is complete.

    let statusPageIntegration = false
    global.VO_STORE.subscribe(() => {
      const integrationsState = global.VO_STORE.getState().integrations
      const statuspage = integrationsState.get('statuspage')

      const previousStatusPageIntegration = statusPageIntegration
      if (statuspage && statuspage.get('key')) {
        statusPageIntegration = true

        // Only do this for the first time that statuspage is properly integrated (ie has a key)
        if (previousStatusPageIntegration != statusPageIntegration) {
          // 1. do fetch of statuspage incidents into manager collection
          // 2. listen for statuspage timeline messages
          // 3. update incidents in manager collection
          // 4. change icon color if anything is other than normal
          spIncMan = new SPIncManager()
          spCompMan = new SPCompManager()

          app.listenTo(
            spIncMan,
            'statuspage:statechange',
            _.bind(updateStatPageIcon, null, spIncMan, spCompMan)
          )
          app.listenTo(
            spCompMan,
            'statuspage:statechange',
            _.bind(updateStatPageIcon, null, spIncMan, spCompMan)
          )
        }
      }
    })
  }
}

app.listenToOnce(vent, 'timeline:initialized', function() {
  check.feature('statusPage').then(displayStatusPage)
})

// render the application base template before initializing everything
const PaneState = _paneState(config.orgslug + ':' + config.socketAuth.USER_ID)

var paneState = new PaneState()

var _getStateOfPanes = function _getStateOfPanes(id) {
  const state = paneState.models
    .find(model => model.id === id)
    .get('view_state')
  return state === 'active'
}

app.on('before:start', function() {
  var template = applicationTpl

  var html = Marionette.Renderer.render(template, config)

  $body.addClass('orgstate-normal').append(html)

  $body.addClass('popout-visible')

  $('.dropdown-toggle').dropdown()

  if (config.auth.user) {
    config.auth.user.username = config.auth.user.username.toLowerCase()
  }

  if (config.billing.state === 'trial') {
    var $timer = $('.leftInTrial')
    var left = moment(config.billing.trial_end).diff(moment(), 'days')

    if (left === 0) {
      $timer.text('today')
    } else if (left === 1) {
      $timer.text('in 1 day')
    } else {
      $timer.text('in ' + left + ' days')
    }
  }

  $body.on('change', '.js-org-switcher select', function(event) {
    var $select = $(event.currentTarget)
    var nextslug = $select.val()
    window.location = '/client/' + nextslug
  })

  $body.on('click', '.js-enter-maintenance', function() {
    var view = new MaintenanceModal()

    app.modalreg.show(view)
  })

  $body.on('click', '.js-exit-maintenance', function() {
    app.modalreg.show(new ModalContents())
  })

  $body.on('click', '.js-setup-control-call', function() {
    app.router.navigate('', { trigger: true, replace: true })
    app.router.hidePerson()

    vent.trigger('controlcall:initCreate')
  })

  $body.on('click', '.js-filter-control', function(e) {
    var $el = $(e.currentTarget)
    app.trigger('changefilter', $el.data('filterkey'))
  })

  $('.js-statuspage-notifications-toggle').on('click', function() {
    app.statusPageNotifications.show(
      new SPNotifcationsView({
        incidents: new SPIncidentColl(),
        components: new SPCompColl(),
      }),
      { forceShow: true }
    )
  })

  check.feature('billingModule').then(function(hasBillingModule) {
    var isPaidAdmin = config.isAdmin && config.billing.state === 'paid'
    var paidAdminLink = !hasBillingModule && isPaidAdmin

    // This should only appear for recurly subscribers
    if (paidAdminLink) {
      $('.js-billing-link').removeClass('hidden')
    }
  })

  // because the filter might be set by a cookie, and need to switch the tab
  app.on('changefilter', function(key) {
    $('.js-filter-control').each(function() {
      if ($(this).data('filterkey') === key) {
        $(this).addClass('current')
      } else {
        $(this).removeClass('current')
      }
    })
  })

  // Pane toggles
  // -------------------------------------------------------------------

  newApp.attach('header')
  paneState.fetch()

  paneState.set([
    {
      pane_id: 'people',
    },
    {
      pane_id: 'timeline',
    },
    {
      pane_id: 'incidents',
    },
  ])

  _.each(paneState.models, function(model) {
    model.save()
  })

  var activePanes = _.partialRight(_.filter, function(m) {
    return m.get('view_state') === 'active'
  })

  var isAllowedState = function(model, newState) {
    if (newState === 'active') {
      return true // bail
    }

    return activePanes(model.collection.models).length > 1
  }

  var updateModel = function updateModel(model) {
    var newState = model.get('view_state') === 'active' ? 'inactive' : 'active'
    var active // init

    if (isAllowedState(model, newState)) {
      model.set('view_state', newState)
      model.save()

      active = activePanes(model.collection.models)

      xlog('panes:state', {
        number: active.length,
        panes: _.map(active, function(m) {
          return m.get('pane_id')
        }),
      })
    }

    return model
  }

  var setIncidentPaneClasses = function() {
    setTimeout(function() {
      // ugh...
      var models = _.filter(paneState.models, function(m) {
        return (
          (m.id === 'timeline' || m.id === 'people') &&
          m.get('view_state') === 'active'
        )
      })

      const paneStates = {
        timeline: _getStateOfPanes('timeline'),
        people: _getStateOfPanes('people'),
        incidents: _getStateOfPanes('incidents'),
      }

      newApp.hydrateHeader({ panes: paneStates })

      var $incidentPane = $body.find('.l-pane-incidents')
      var incidentPaneWidth = $incidentPane.width()

      if (models.length < 2 && incidentPaneWidth > 719) {
        $('.l-pane-wrapper.l-pane-incidents')
          .removeClass('l-pane-small')
          .addClass('l-pane-medium')
      } else {
        $('.l-pane-wrapper.l-pane-incidents')
          .removeClass('l-pane-medium')
          .addClass('l-pane-small')
      }

      var timelinePaneState = _.head(
        _.filter(paneState.models, m => m.id === 'timeline')
      )

      if (timelinePaneState) {
        vent.trigger(
          'reacttimeline:backboneTimelinePaneStateChange',
          timelinePaneState.get('view_state')
        )
      }

      vent.trigger('reacttimeline:backBoneContainerRendered', Date.now())
    }, 0)
  }

  // set initial view state of incident pane, and respond when either of
  // the other two panes change visibility state
  this.listenTo(paneState, 'change', setIncidentPaneClasses)
  $(window).on('resize incidentPane:setclasses', setIncidentPaneClasses)
  setIncidentPaneClasses()

  var updateDOM = function updateDOM(pane, state) {
    switch (state) {
      case 'active':
        $body.addClass(`${pane}-active`)
        break

      case 'inactive':
        $body.removeClass(`${pane}-active`)
        break

      default:
        logMessage('bad state')
        break
    }
  }

  _.each(['people', 'timeline', 'incidents'], function(pane) {
    var $el = $body.find(`.js-pane-switch--${pane}`)
    var model = _.find(paneState.models, { id: pane })
    updateDOM(pane, model.get('view_state'))

    $el.on('click', () => updateDOM(pane, updateModel(model).get('view_state')))
  })

  app.listenTo(vent, 'incidentDetails:pane:open', function() {
    const model = paneState.get('incidents')
    model.set('view_state', 'active')
    model.save()
    updateDOM('incidents', 'active')
  })

  app.listenTo(vent, 'people:pane:open', function() {
    const model = paneState.get('people')
    model.set('view_state', 'active')
    model.save()
    updateDOM('people', 'active')
  })
})

// this is the observable function that handles the offline banner
function bannerControl(statusObservable) {
  debug('VO:socketstatus')(`listening`)

  let isBannerVisible = false
  const delayLength = 10000

  function next({ state }) {
    debug('VO:socketstatus')(
      `closed/disconnecting - show banner?: ${!isBannerVisible}`
    )

    if (isBannerVisible) {
      return // bail
    } else {
      isBannerVisible = true
    }

    vent.trigger('reacttimeline:clearTimelineMessages')

    newApp.attach('notificationsContainer', { banner: 'offline' })
    newApp.dispatch(
      toggleOfflineMode({
        COMPANY_OFFLINE: true,
      }),
      function() {
        document.querySelector('#app-mask').classList.add('is-visible')
      }
    )
  }

  const error = err => debug('VO:socketstatus')(`error: ${err}`)

  function done() {
    debug('VO:socketstatus')(`connected`)

    vent.trigger('banner:hide', 'socketstatus-v2')
    newApp.dispatch(toggleOfflineMode({ COMPANY_OFFLINE: false }), function() {
      document.querySelector('#app-mask').classList.remove('is-visible')
    })

    // Remove overlay
    $('#app-mask').removeClass('is-visible')
    setTimeout(
      () => $('#notification-banner').removeClass('notification-banner--gold'),
      0
    )

    // re-listen
    setTimeout(() => bannerControl(statusObservable), 0)
  }

  const closed = statusObservable.filter(({ state }) => state === 'closed')
  const connected = statusObservable.filter(
    ({ state }) => state === 'connected'
  )
  const disconnecting = statusObservable.filter(
    ({ state }) => state === 'disconnecting'
  )

  disconnecting
    .merge(closed)
    .delay(delayLength)
    .takeUntil(connected)
    .subscribe(next, error, done)
}

bannerControl(server.conn.status)

// display fixups
app.on('start', function() {
  Backbone.history.start()

  // initialize chatrooms (do it before fixing the scrolling)
  $('.contactinfo').each(function() {
    var $el = $(this)
    var height = $('.contactinfo-container', $el).outerHeight()
    $('.chatroom', $el).css({ paddingTop: height })
  })

  // initialize scrollables
  var scrollables = $('.scrollable-bottom')
  scrollables.each(function() {
    var el = $(this)
    el.scrollTop(el.outerHeight() * 2)
  })

  $('#people').on('click', '.js-profile-pane-close', function() {
    app.router.navigate('', { trigger: true, replace: true })
    app.router.hidePerson()
  })

  $('#incidents').on('click', '.js-incident-pane-close', function() {
    app.router.navigate('', { trigger: true, replace: true })
    app.router.hideIncident()
  })

  $('.js-controlcall').click(function() {
    var cc = new ControlCallInit()
    var userInvite = new UserInvite({
      template: $('#people').clone(),
    })

    vent.trigger('modal:small:show', cc)
    vent.trigger('user_invite:show', userInvite)

    app.listenToOnce(vent, 'controlcall:modal:close', function() {
      vent.trigger('user_invite:empty')
    })
  })
})

app.listenTo(vent, 'banner:show', function(banner, options) {
  // need better scheduling here... these listeners should need to be
  // debounced or something...
  newApp.attach('notificationsContainer', { banner })
  var bannerView = app.bannerView || new BannerContainer()

  bannerView.addView(banner, options)
  app.bannerView = bannerView
})

app.listenTo(vent, 'banner:hide', function(banner, id) {
  if (app.bannerView) {
    app.bannerView.removeView(banner, id)
  }
})

server.on('protocol:unauthorized', function() {
  var view = new Reauth()

  app.modalreg.show(view)

  vent.trigger('banner:hide', 'socketstatus-v2')
})

server.on('protocol:version_update', function() {
  var view = new Reload()
  app.modalreg.show(view)
})

server.on('protocol:authenticated', function() {
  _.extend(config, {
    routeKeys: getRouteKeys({
      groups: data.groups,
      org: config.auth.org.slug,
      user: config.auth.user.username,
      users: data.users,
    }),
  })

  // @TODO: remove
  //
  // This function can go away once the css transition is restored. The
  // Safari bug referenced below has been fixed. Workaround for Safari
  // + calc + transition crash: http://bit.ly/1RD0rK0
  function setContainerHeights(opts) {
    var $target = $('.js-application-panes')
    var targetHeight =
      opts && opts.resize ? $(window).height() : $target.height()
    const headerHeight = $('#primary--header').height()
    $target.height(targetHeight - headerHeight)
    $target.offset({ top: headerHeight })
  }

  // Rolodex
  // -------------------------------------------------------------------

  app.rolodex.show(new RolodexContainer())

  // Primary timeline
  // -------------------------------------------------------------------

  // kicking off the timeline, subscribed to "*" by default, so as not
  // to block first render of a timeline
  var opts = { ROOM_ID: '*', subtype: 'primary' } // defaults

  opts.paneState = paneState
  app.timeline.show(new TimelineContainer(opts))

  /* eslint-disable */
  new IncidentCounter({
    el: $('.js-incidents-wrapper'),
    vent: app,
  })
  /* eslint-enable */

  // This is the event that gets fired upon entering maintenance mode
  data.orgstate.on('change:suspended', function(model, suspended) {
    var event = suspended ? 'banner:show' : 'banner:hide'

    newApp.attach('notificationsContainer')
    vent.trigger(event, 'maintenance')
  })

  data.controlcalls.on('add', function(call) {
    vent.trigger('banner:show', 'controlcall', { model: call })
  })

  data.controlcalls.on('remove', function(call) {
    vent.trigger('banner:hide', 'controlcall', call.id)
  })

  // toggle the control call button when a call is started and stopped
  // TODO: may want to support simultaneous calls in the future...
  if (_.includes(data.features, 'confcall')) {
    $('.js-setup-control-call')
      .parent()
      .removeClass('hidden')
  }

  data.features.on('features:reload', function() {
    var canCall = false
    var $controlCallTrigger = $('.js-setup-control-call').parent()

    if (
      _.includes(data.features, 'confcall') &&
      !_.includes(data.features, 'conferencebridges') &&
      data.controlcalls.length === 0
    ) {
      $controlCallTrigger.removeClass('hidden')
      canCall = true
    } else {
      $controlCallTrigger.addClass('hidden')
    }

    vent.on('banner:show', function(banner) {
      if (banner === 'controlcall' && canCall) {
        $controlCallTrigger.addClass('hidden')
      }
    })

    vent.on('banner:hide', function(banner) {
      if (banner === 'controlcall' && canCall) {
        $controlCallTrigger.removeClass('hidden')
      }
    })

    if (data.controlcalls.length > 0) {
      $controlCallTrigger.addClass('hidden')
    }
  })

  var debouncedSetContainerHeights = _.debounce(function() {
    setContainerHeights({
      resize: true,
    })
  }, 500)

  $(window).on('resize', function() {
    setContainerWidths()
    debouncedSetContainerHeights()
  })

  var $orgstate = $('.js-orgstate')
  var $incidentsWrapper = $('.js-incidents-wrapper .l-scrollable')
  var lastScrollPosition = 0

  server.on('state:maintenanceMode', function(data) {
    const parsedInstances = data.ACTIVE_INSTANCES.map(activeInstance => ({
      instanceId: activeInstance.INSTANCE_ID,
      isGlobal: activeInstance.IS_GLOBAL,
      startedAt: activeInstance.STARTED_AT,
      startedBy: activeInstance.STARTED_BY,
      targets: activeInstance.TARGETS.map(target => ({
        names: target.NAMES,
        type: target.TYPE,
      })),
    }))

    newApp.dispatch(
      maintenanceUpdate({
        companyId: data.COMPANY_ID,
        activeInstances: parsedInstances,
      })
    )
  })

  var setIncidentsWrapperTop = function() {
    // I don't know why this 1px offset is needed...
    return $incidentsWrapper.css('top', $orgstate.outerHeight() - 1)
  }

  $incidentsWrapper.on(
    'scroll',
    _.debounce(
      _.bind(function(e) {
        // debounce fixes a bug where 2nd header doesn't always get unstuck

        var scrollTop = $(e.currentTarget).scrollTop()
        var scrollDirection = scrollTop > lastScrollPosition ? 'down' : 'up'
        var $nextHeaderToHide = $incidentsWrapper
          .find('.js-toggle-incidents')
          .not('.hidden-by-scroll')
          .first()
        var $nextHeaderToShow = $incidentsWrapper
          .find('.js-toggle-incidents.hidden-by-scroll')
          .last()
        var hiddenByScroll
        var shownByScroll
        var key
        var $nextStickyHeader

        if (scrollDirection === 'down' && $nextHeaderToHide.length) {
          hiddenByScroll = $nextHeaderToHide.position().top <= 0
          key = $nextHeaderToHide.data('incidents')
          $nextStickyHeader = $orgstate.find(
            '.js-toggle-incidents[data-incidents=' + key + ']'
          )

          if (hiddenByScroll) {
            $nextHeaderToHide.addClass('hidden-by-scroll')
            $nextStickyHeader.removeClass('hidden')
          }
        } else if (scrollDirection === 'up' && $nextHeaderToShow.length) {
          shownByScroll = $nextHeaderToShow.position().top >= 0
          key = $nextHeaderToShow.data('incidents')
          $nextStickyHeader = $orgstate.find(
            '.js-toggle-incidents[data-incidents=' + key + ']'
          )

          if (shownByScroll) {
            $nextHeaderToShow.removeClass('hidden-by-scroll')
            $nextStickyHeader.addClass('hidden')
          }
        }

        $orgstate.toggleClass('is-scrolled', scrollTop > 0)
        lastScrollPosition = scrollTop
      }, this),
      0
    )
  )

  setTimeout(function() {
    $('#applicationloading').hide(10, function() {
      $('.js-application-panes').show(10, function() {
        setContainerWidths()
        setIncidentsWrapperTop()
        setContainerHeights({ resize: true })
        $(window).trigger('incidentPane:setclasses')

        _.each(['people', 'timeline', 'incidents'], function(pane) {
          var $el = $body.find(`.js-pane-switch--${pane}`)

          $el.on('click', setContainerWidths)
        })

        newApp.attach()
      })
    })
  }, 0)
})

// set up the router
app.addInitializer(function() {
  var Router = routerFactory($body)

  app.router = new Router({
    regions: { incident: app.incident, person: app.person },
  })
})

app.toaster = Toastr
window.app = app
export default app
