// vendor
import Marionette from 'marionette'
import _ from 'lodash'
import 'backbone.stickit'

// lib
import data from '../../data'
import interpolate from 'util/interpolate_engagement'
import App from '../../app'
import FlatContactTeamTpl from 't/rolodex/flat.contact.team.tpl'
import FlatContactTpl from 't/rolodex/flat.contact.tpl'
import TeamsContactTpl from 't/rolodex/teams.contact.tpl'

var BaseContact = Marionette.ItemView.extend({
  initialize: function(opts) {
    this.tag = opts.tagName || 'div'
    this.actionList = opts.actionList

    // Toggle state classes
    this.listenTo(this.actionList, 'add', _.bind(this.onHeardAdd, this))
    this.listenTo(this.actionList, 'remove', _.bind(this.onHeardRemove, this))
  },

  // Overrides
  // -------------------------------------------------------------------

  className: 'rolodex-list-item rolodex-list-item--contact',

  events: {
    click: 'onHandleClick',
  },

  tagName: function() {
    return this.tag
  },

  // Events
  // -------------------------------------------------------------------

  onHandleClick: function() {
    if (this.actionList) {
      this.onSelect() // this is so brittle
    } else {
      this.onShowPerson()
    }
  },

  onHeardRemove: function(model) {
    if (this.model.id === model.id) {
      this.$el.removeClass('rolodex-selected')
    }
  },

  onHeardAdd: function(model) {
    if (this.model.id === model.id) {
      this.$el.addClass('rolodex-selected')
    }
  },

  onSelect: function() {
    if (this.actionList.contains(this.model)) {
      this.actionList.remove(this.model)
    } else {
      this.actionList.add(this.model)
    }
  },

  onShowPerson: function() {
    var userID = this.model.get('USER_ID')

    App.router.navigate('person/' + userID, { trigger: true, replace: true })
  },

  onRender: function() {
    this.stickit()

    if (this.actionList) {
      this.$el.addClass('rolodex-selectable')

      if (this.actionList.contains(this.model)) {
        this.$el.addClass('rolodex-selected')
      }
    }
  },
})

var Ungrouped = BaseContact.extend({
  // Overrides
  // -------------------------------------------------------------------

  bindings: {
    '.js-missed': {
      observe: 'MISSED',

      onGet: function() {
        return ''
      },

      attributes: [
        {
          name: 'class',

          observe: 'MISSED',

          onGet: function(missed) {
            return missed > 0 ? 'js-missed rolodex-missed' : 'js-missed'
          },
        },
      ],
    },

    '.js-rolodex-contact-engagement div': {
      attributes: [
        {
          name: 'class',

          observe: 'ENGAGEMENT',

          onGet: function(engagement) {
            var interpolated = interpolate(engagement)
            var engagementLevel // init

            switch (interpolated) {
              case 1:
                engagementLevel = 'on'
                break

              default:
                engagementLevel = 'off'
                break
            }

            return engagementLevel
          },
        },
      ],
    },

    '.rolodex-contact-oncall-icon': {
      attributes: [
        {
          name: 'class',

          observe: 'oncall',

          onGet: function(oncalls) {
            var isUserOnCall = oncalls.length > 0

            return isUserOnCall ? '' : 'hide-icon'
          },
        },
      ],
    },

    '.js-contact-teams': {
      observe: ['group-slugs', 'oncall'],

      update: function($el, values) {
        const groups = _.uniq(_.flatten(_.last(values))) // Last is referring to the on call array
        const tpl = FlatContactTeamTpl

        // Create DOM string from _.template(). State is
        // determined by whether or not this group is in the
        // oncalls array.
        const list = _.map(groups, function(group) {
          const groupName = data.groups
            .findWhere({ GROUP_ID: group })
            .get('NAME')

          const opts = {
            state: 'on',
            team: groupName,
          }

          return tpl(opts)
        })

        $el.empty()
        $el.append(list.join(''))
      },
    },
  },

  template: FlatContactTpl,
})

var Grouped = BaseContact.extend({
  initialize: function(options) {
    this.parentTeam = options.teamName

    BaseContact.prototype.initialize.apply(this, arguments)
  },

  // Overrides
  // -------------------------------------------------------------------

  bindings: {
    '.js-missed': {
      observe: 'MISSED',

      onGet: function() {
        return ''
      },

      attributes: [
        {
          name: 'class',

          observe: 'MISSED',

          onGet: function(missed) {
            return missed > 0 ? 'js-missed rolodex-missed' : 'js-missed'
          },
        },
      ],
    },

    '.js-rolodex-contact-engagement div': {
      attributes: [
        {
          name: 'class',

          observe: 'ENGAGEMENT',

          onGet: function(engagement) {
            var interpolated = interpolate(engagement)
            var engagementLevel // init

            switch (interpolated) {
              case 1:
                engagementLevel = 'on'
                break

              default:
                engagementLevel = 'off'
                break
            }

            return engagementLevel
          },
        },
      ],
    },

    '.rolodex-contact-oncall-icon': {
      attributes: [
        {
          name: 'class',

          observe: 'oncall',

          onGet: function(oncalls) {
            var isUserOnCall = _.includes(oncalls, this.parentTeam)

            return isUserOnCall ? '' : 'hide-icon'
          },
        },
      ],
    },
  },

  template: TeamsContactTpl,
})

export default function(which) {
  if (which === 'grouped') {
    return Grouped
  } else {
    return Ungrouped
  }
}
