import _ from 'vendor/lodash'
import $ from 'lib/jquery'
import Marionette from 'lib/marionette'
import { debounce } from 'lodash'

import vent from 'util/vent'
import data from '../data'
import server from '../server'
import ContactInfoChatTpl from 't/contact.info.chat.tpl'

var config = window.VO_CONFIG

export default Marionette.ItemView.extend({
  template: ContactInfoChatTpl,

  className: 'private-chat',

  ui: {
    chatroom: '.js-private-chat',
  },

  initialize: function() {
    _.bindAll(this, [
      'serializeData',
      'onRender',
      'sendChatCheckpoint',
      'addChatMessage',
      'resetScroll',
      '_renderChatMessage',
      'sendChat',
    ])

    this.collection = data.timelines.getOrCreate(
      this.model.privateTimeline()
    ).events

    this.listenTo(this.collection, 'add', this.addChatMessage)
  },

  events: {
    'keypress .js-editor': 'sendChat',
  },

  serializeData: function() {
    return this.model
  },

  onRender: function() {
    this.sendChatCheckpoint()

    // @see timeline.js
    // If an image load fails, remove the linkedImage wrapper.
    this.$el.get(0).addEventListener(
      'error',
      function(event) {
        var $target = $(event.target)
        if ($target.is('img')) {
          $target.closest('.linkedImage').empty()
        }
      },
      true
    )

    // true = capture! important! image errors don't bubble, so we have to get them with this. *sigh*

    this.collection.each(_.bind(this._renderChatMessage, this, true))

    this.listenTo(vent, 'private-chat:scroll:reset', this.resetScroll)
  },

  sendChatCheckpoint: debounce(function() {
    server.sendChatCheckpoint(this.model.privateTimeline())
  }, 333),

  addChatMessage: function(chat) {
    if (chat.get('USER_ID') !== config.socketAuth.USER_ID.toLowerCase()) {
      this.sendChatCheckpoint()
    }

    this._renderChatMessage(false, chat)

    this.resetScroll()
  },

  resetScroll: function() {
    if (this.ui.chatroom.length) {
      this.ui.chatroom[0].scrollTop = this.ui.chatroom[0].scrollHeight
    }
  },

  _renderChatMessage: function(isFirstRender, msg) {
    var wrapper = $(
      "<li class='base-msg entry-time-wrapper chat person-chat' />"
    )
    var li = $("<div class='chat-bubble' />").appendTo(wrapper)

    // html, not text, since it's escaped on the server
    li.html('<span class="what">' + msg.get('parsedText') + '</span>')
    li.append(
      "<span class='entry-time'>" + msg.formatTime(msg.timestamp()) + '</span>'
    )

    var img = msg.get('parsedImage')

    if (img) {
      wrapper.append(
        "<div class='linkedImage'><a target='_blank'><img /></a></div>"
      )
      wrapper
        .find('.linkedImage')
        .find('a')
        .attr('href', img)
        .end()
        .find('img')
        .attr('src', img)
    }

    li.append("<div class='force-clearfix' />")

    if (msg.get('USER_ID') === config.socketAuth.USER_ID.toLowerCase()) {
      wrapper.addClass('me')
    }

    if (isFirstRender) {
      this.ui.chatroom.prepend(wrapper)
    } else {
      this.ui.chatroom.append(wrapper)
    }
  },

  sendChat: function(e) {
    if (e.which === 13 && e.ctrlKey !== true) {
      var $t = $(e.target)
      var msg = $t.val().trim()

      if (msg !== '') {
        server.sendChat(this.model.privateTimeline(), $t.val())
        $t.val('')
      }

      e.preventDefault()
    }
  },
})
