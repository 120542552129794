// vendor
import $ from 'jquery'
import Backbone from 'backbone'
import { bind, isUndefined, partial } from 'lodash'

// lib
import vent from 'util/vent'
import check from 'util/check'
import data from './data'
import ContactInfoTpl from 't/contact.info.tpl'
import ProfileChat from './views/profile-chat'
import Incident from './views/incident'
import ReactIncidentDetails from './views/r-incident-details'
import server from 'app/server'
import { mergeRouterOptions } from 'components/incident-details/related-incidents'
import getNoIncidentModal from 'components/incidents/view/no-incident-modal'
import store from 'components/store/app-timeline'
import { showModal } from 'components/store/actions'
import { getFeatureFlags } from 'components/store/selectors'
import { logMessage } from '../util/monitoringService'

const voStore = global.VO_STORE || store()

const config = window.VO_CONFIG

export default function buildRouter($body) {
  return Backbone.Router.extend({
    initialize(options) {
      this.options = options
    },

    routes: {
      'person/:id': 'showPerson',
      'incident/:id': 'showIncident',
      'incident/:id/:tab': 'showIncident',
    },

    showPerson(id, tries) {
      const router = this
      vent.trigger('people:pane:open')

      if (isUndefined(tries)) {
        tries = 0
      }

      if (isUndefined(data.users.get(id)) && tries < 5) {
        var showPerson = partial(this.showPerson, id, tries + 1)

        setTimeout(bind(showPerson, router), 250)

        return
      }

      if (isUndefined(data.users.get(id))) {
        router.navigate('', { replace: true })

        return
      }

      router._loadProfileChat(ContactInfoTpl, id)

      $('#people')
        .addClass('pane-container--focus-left')
        .removeClass('pane-container--focus-right')
    },

    _loadProfileChat(template, id) {
      var profileChat = new ProfileChat({
        model: data.users.get(id),
        template: template,
      })

      this.options.regions.person.show(profileChat)
    },

    hidePerson() {
      $body.removeClass('focus-person')

      $('#people')
        .addClass('pane-container--focus-right')
        .removeClass('pane-container--focus-left')

      this.options.regions.person.empty()
    },

    showIncident(id, tab, tries) {
      function renderView(model, incidentView, options) {
        document.title = `#${model.get('INCIDENT_NAME')} - ${model.get(
          'ENTITY_ID'
        )}`
        vent.trigger('incidentDetails:pane:open')
        return options.regions.incident.show(incidentView)
      }

      if (isUndefined(tries)) {
        tries = 0
      }

      if (isUndefined(data.incidents.get(id)) && tries < 5) {
        var showIncident = partial(this.showIncident, id, tab, tries + 1)
        setTimeout(bind(showIncident, this), 250)

        return
      }

      const tabOpts = {
        alertAnnotations: 'alertAnnotations',
        alertDetails: 'alertDetails',
        incidentTimeline: 'incidentTimeline',
      }

      // hide new tab behind local flag
      // this will be used to demo at .conf
      const flaggedOptions = getFeatureFlags(voStore.getState()).get(
        'feature:similarincidents'
      )
        ? mergeRouterOptions(tabOpts)
        : tabOpts

      if (!flaggedOptions[tab]) {
        window.location.hash = `#/incident/${id}/alertDetails`
        return // bail router will rerun with above hash change
      }

      const activeTab = flaggedOptions[tab] || 'alertDetails'

      if (isUndefined(data.incidents.get(id))) {
        const _options = this.options
        server.getIncidents([id], resp => {
          if (!resp.PAYLOAD.INCIDENTS[0]) {
            voStore.dispatch(
              showModal({
                modalType: 'info',
                modalProps: {
                  title: "We couldn't find the incident you are searching for.",
                  body: getNoIncidentModal(),
                  cancelButtonText: 'Ok',
                  cancelButtonType: 'info',
                  suppressOutsideClick: false,
                },
              })
            )
            logMessage('incident not found', { attributes: { incidentId: id } })
            this.hideIncident()
            return this.navigate('', { replace: true })
          }
          data.incidents.add(resp.PAYLOAD.INCIDENTS[0])
          var model = data.incidents.get(id)

          return check.feature('reactIncidentDetails').then(hasFeature => {
            if (hasFeature) {
              var incidentDetails = new ReactIncidentDetails({
                id: id,
                uuid: model.get('LAST_UUID'),
                activeTab: activeTab,
              })
              return renderView(model, incidentDetails, _options)
            } else {
              var incident = new Incident({ model: model, groups: data.groups })
              return renderView(model, incident, _options)
            }
          })
        })
      } else {
        var model = data.incidents.get(id)
        check.feature('reactIncidentDetails').then(hasFeature => {
          if (hasFeature) {
            var incidentDetails = new ReactIncidentDetails({
              id: id,
              uuid: model.get('LAST_UUID'),
              activeTab: activeTab,
            })
            return renderView(model, incidentDetails, this.options)
          } else {
            var incident = new Incident({ model: model, groups: data.groups })
            return renderView(model, incident, this.options)
          }
        })
      }

      $('#incidents')
        .addClass('pane-container--focus-right')
        .removeClass('pane-container--focus-left')
    },

    hideIncident() {
      // Reset window title
      document.title = `Timeline - ${config.orgname}`

      $('#incidents')
        .addClass('pane-container--focus-left')
        .removeClass('pane-container--focus-right')
    },
  })
}
