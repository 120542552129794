// vendor
import Marionette from 'marionette'
import _ from 'lodash'

// lib
import Component from './component'
// import log from 'util/_console'
import vent from 'util/vent'

var EmptyComponentsView = Marionette.ItemView.extend({
  template: function() {
    return _.template(
      '<div><p>You have no components. Get Some! ヽ(^。^)丿</p></div>'
    )
  },
})

export default Marionette.CompositeView.extend({
  // Overrides
  // -------------------------------------------------------------------

  initialize: function() {
    this.pCollection = this.collection.fetch()
  },

  template: require('t/statuspage/modals/components.tpl'),

  ui: {
    cancel: '.js-statuspage-cancel',
    update: '.js-statuspage-update-components',
  },

  events: {
    'click @ui.cancel': 'onCancel',
    'click @ui.update': 'onUpdate',
  },

  collectionEvents: {
    request: 'showLoading',
    sync: 'hideLoading',
  },

  childView: Component,

  emptyView: EmptyComponentsView,

  childViewContainer: '.js-statuspage-control-group',

  onRender: function() {
    this.pCollection
      .done(_.bind(this.onCollectionFetch, this))
      .fail(_.bind(this.onCollectionFail, this))
  },

  // Custom
  // -------------------------------------------------------------------

  showLoading: function() {
    this.$('.js-loading').show()
  },

  hideLoading: function() {
    this.$('.js-loading').hide()
  },

  onCollectionFetch: function() {
    this.$('.js-statuspage-update-components').removeClass('hidden')
  },

  onCollectionFail: function() {
    this.$('.js-statuspage-control-group')
      .empty()
      .append(
        '<div><p>Something went wrong while fetching your components. (╥_╥)</p></div>'
      )
  },

  onUpdate: function(e) {
    e.preventDefault()

    this.collection.save()

    vent.trigger('statuspage:container:empty')
  },

  onCancel: function(e) {
    e.preventDefault()

    vent.trigger('statuspage:container:empty')
  },
})
