// vendor
/* global ga */
import $ from 'jquery'
import _ from 'lodash'

// lib
import client from './app'
import isDebug from 'util/isDebug'
import server from './server'
import startIncidentsLoadTimer from 'util/startIncidentsLoadTimer'
import data from './data'
import vent from 'util/vent'
import { logError } from '../util/monitoringService'

var config = window.VO_CONFIG

startIncidentsLoadTimer()

if (window.heap) {
  window.heap.identify(config.auth.user.username)
  window.heap.addUserProperties({
    org_slug: config.auth.org.slug,
  })
}

export default function() {
  // not sure if this variable is ever used
  config.debug = isDebug()

  function trackConversion() {
    $.removeCookie('signup_email', { domain: '.victorops.com' })

    ga('wwwTracker.send', 'pageview', {
      page: '/funnel/signup/complete',
      transport: 'beacon',
    })
  }

  // on dom ready, bring the client up
  $(document).ready(function() {
    data.meta
      .getOrgMeta()
      .done(function(orgmeta) {
        if (_.isUndefined(orgmeta['funnel:complete'])) {
          data.meta.putOrgMeta({
            'funnel:complete': true,
          })

          if (_.isUndefined(window.gaLoad)) {
            // Wait for GDPR wrapper callback for inserting script
            $(window).on('gaLoad', trackConversion)
          } else {
            // GDPR wrapper has already run
            trackConversion()
          }
        }
      })
      .fail(function(xhr, status, thrown) {
        var err = {
          jqXHR: xhr,
          textStatus: status,
          errorThrown: thrown,
        }

        logError(err)
      })

    client.start()
  })

  // start the server as part of the application's initialization
  client.addInitializer(function() {
    if (Storage && sessionStorage.getItem('socketproxy')) {
      const wsProtocol = window.location.protocol === 'http:' ? 'ws:' : 'wss:'
      server.connect(
        `${wsProtocol}//${window.location.host}/chaos${sessionStorage.getItem(
          'socketproxy'
        )}`
      )
    } else {
      server.connect()
    }

    // on the first connection, ask for the last 50 from the * room
    server.once('protocol:authenticated', function() {
      // server.requestHistory("*", 50);
      vent.trigger('incidentsLoadTimer', { authenticated: true })
    })

    // on subsequent connections, loop through each timeline and request history up to what we've already got
    server.on('protocol:reauthenticated', function() {
      data.timelines.each(function(t) {
        server.requestHistoryFrom(t.get('ROOM_ID'), t.get('LATEST'))
      })
    })
  })
}
